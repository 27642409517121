import React from "react";
import PropTypes from "prop-types";
import { ContactSection } from "../sections/ContactSection/ContactSection";
import Paginator from "../components/Paginator";
import { BlogItem } from "../sections/BlogSection/BlogItem";

const PaginatedBlog = ({ pageContext }) => {
  const { pageCount, group, index, first, last } = pageContext;

  return (
    <React.Fragment>
      <div className="blog-page">
        <div className="page-links-and-page-title">
          <div className="page-links">
            <a href="/" className="page-link">
              Home
            </a>
            <span className="page-links-seperator">/</span>
            <a href="/blog" className="page-link">
              Blog
            </a>
          </div>
          <h1 className="title-page">Blog</h1>
        </div>
        <div className="blog-and-descripton-container">
          <hr className="custom-hr" />
          <p className="blog-short-description">
            From deep technical topics to current business trends our articles have you covered.
          </p>
          <ul className="blog-posts-wrap">
            {group.map(post => {
              return <BlogItem key={post.node.frontmatter.title} post={post.node} />;
            })}
          </ul>
          <Paginator page={index} isFirst={first} isLast={last} numberPages={pageCount} />
        </div>
        <ContactSection />
      </div>
      <style>{`
        .page-links-and-page-title {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
        }
        .blog-short-description {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.3;
          color: #141418;
          max-width: 550px;
          letter-spacing: -0.02em;
        }
        .page-links {
          font-family: Neutrif Pro;
          font-style: italic;
          font-weight: normal;
          line-height: 1.1;
          color: #0005FB;
        }
        .page-link {
          color: #0005FB;
          text-transform: uppercase;
        }
        .title-page {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.1;
          letter-spacing: -0.02em;
          color: #141418;
        }

        @media screen and (min-width: 320px) {
          .blog-page {
            margin-top: 40px;
          }
          .title-page {
            font-size: 44px;
          }
          .page-links {
            font-size: 16px;
          }
          .page-links-seperator {
            margin: 0 5px;
          }
          .blog-short-description {
            font-size: 24px;
            margin: 25px 0 50px;
          }
          .page-links-and-page-title {
            padding: 0 20px;
          }
          .blog-and-descripton-container {
            margin-top: 20px;
            padding: 0 20px;
          }
        }

        @media screen and (min-width: 768px) {
          .title-page {
            font-size: 104px;
          }
          .blog-and-descripton-container {
            margin-top: 40px;
          }
        }

        @media screen and (min-width: 1080px) {
          .blog-and-descripton-container {
            padding: 0 70px 0 160px;
            margin-top: 100px;
          }
          .page-links-seperator {
            margin: 0 15px;
          }
          .blog-short-description {;
            font-size: 36px;
            margin: 30px 0 70px;
          }
          .page-links-and-page-title {
            padding: 0 70px;
          }
          .page-links {
            font-size: 20px;
          }
          .title-page {
            font-size: 144px;
            text-transform: lowercase;
          }
          .blog-page {
            margin-top: 115px;
          }
        }
        @media screen and (min-width: 1300px) {
          .blog-and-descripton-container {
            padding: 0 130px 0 220px;
          }
          .page-links-and-page-title {
            padding: 0 130px;
          }
        }
    `}</style>
    </React.Fragment>
  );
};

PaginatedBlog.propTypes = {
  pageContext: PropTypes.object
};

export default PaginatedBlog;
