import React from "react";
import { ContactSectionContent } from "./ContactSectionContent";

const ContactSection = () => {
  return (
    <React.Fragment>
      <section id="contactUs" className="section-style contact-section">
        <ContactSectionContent />
      </section>
    </React.Fragment>
  );
};

export { ContactSection };
