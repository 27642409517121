import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

const Paginator = ({ numberPages = 1, page, isFirst, isLast }) => {
  const pagesArr = useMemo(() => new Array(numberPages).fill(" "), [numberPages]);

  const prevPage = useCallback(() => {
    if (!isFirst) {
      const previousPageUrl = page - 1 === 1 ? "/blog/" : `/blog/${page - 1}`;
      navigate(previousPageUrl);
    }
  }, [isFirst, page]);

  const nextPage = useCallback(() => {
    if (!isLast) {
      navigate(`/blog/${page + 1}`);
    }
  }, [isLast, page]);

  const handleClick = useCallback(index => {
    navigate(`/blog/${index ? index + 1 : ""}`);
  }, []);

  return (
    <React.Fragment>
      <div className="paginator-container ">
        <div className="paginator-item">
          <div className="paginator-content">
            <div className="pages">
              <button
                type="button"
                className={`paginator-link-text ${page === 1 ? "hidden-link" : ""}`}
                onClick={prevPage}
              >
                Previous
              </button>
              <div>
                {pagesArr.map((p, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handleClick(index)}
                    type="button"
                    className={`paginator-link ${index + 1 === page ? "active-link" : ""}`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
              <button
                type="button"
                className={`paginator-link-text ${page === numberPages ? "hidden-link" : ""}`}
                onClick={nextPage}
              >
                Next
              </button>
            </div>
            <div className="spacer" />
          </div>
        </div>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .paginator-item {
          grid-column: 2/4;
          justify-self: stretch;

          .paginator-content {
            display: flex;
            justify-content: space-between;
          }

          .pages {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }

          .paginator-link,
          .paginator-link-text {
            font-family: "Neutrif Pro";
            font-style: normal;
            font-weight: 400;
            line-height: 1.4;
            font-size: 18px;
            cursor: pointer;
            width: 35px;
            height: 35px;
            border: 1px solid #fff;

            color: #141418;
            background-color: transparent;
            transition: color 0.2s ease-in-out;
            transition: border 0.2s ease-in-out;
          }
          .paginator-link {
            margin-right: 5px;
          }
          .paginator-link:hover {
            color: #0005fb;
            border: 1px solid #b7b7c7;
          }
          .active-link {
            border: 1px solid #b7b7c7;
          }
          .hidden-link {
            opacity: 0;
            cursor: unset;
          }
          .paginator-link-text:hover {
            color: #0005fb;
          }
        }

        .paginator-container {
          margin-top: 85px;
        }

        @media screen and (min-width: 1080px) {
          .paginator-container {
            display: grid;
            grid-gap: 0 40px;
            justify-content: space-between;
            grid-template-columns: minmax(100px, 220px) minmax(200px, 515px) minmax(200px, 300px);
          }
          .spacer {
            display: block;
            min-width: 300px;
            max-width: 340px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default Paginator;

Paginator.propTypes = {
  numberPages: PropTypes.number,
  page: PropTypes.number,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
};
